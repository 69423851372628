.respass-container {
  background-image: url("../../assets/login-bg.png");
}
.login-container {
  background-image: url("../../assets/login-bg.png");

  .wrapper {
    background: #ffffff;
    width: 500px;

    img {
      width: 250px;
    }
  }

  .title:after {
    content: "";
    border-bottom: 3px solid #e11b22;
    width: 50px;
    display: block;
  }

  .title p {
    color: #000000;
  }

  .forgotPass {
    color: #0c91ec;
    a {
      color: #0c91ec;
    }
  }
}
