.header {
  background: #ffffff;
  height: 70px;
  z-index: 1;

  .header-logo {
    height: 30px;
  }

  .user-logo {
    border: 3px solid #f3f4f5;
    height: 40px;
  }

  .logout {
    color: #3c3c3c;
  }
}
