#content {
  width: calc(100vw - 110px);
  height: calc(100vh - 70px);
  margin-top: 70px;
  transition: all 0.3s;

  .contentDiv {
    background: lightgrey;
  }
}
