.table-container {
  .accIconOpen,
  .accIconClose {
    content: "";
    height: 10px;
    width: 10px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
  }
  .accIconOpen {
    transform: rotate(-135deg);
  }

  th {
    background: #f4f3f4;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: #717171;
    text-align: start;
    position: sticky;
    top: 0;
  }

  td {
    background: #ffffff;
    border-bottom: 1px solid #dadada;
    font-weight: 500;
    font-size: 0.78rem;
    line-height: 19px;
    color: #000000;
  }
}

.pagination {
  li,
  button {
    color: #000000;
    background: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    width: 32px;
    height: 32px;
  }

  button {
    padding: 10px;
  }

  li.active,
  li:hover,
  button.active,
  button:hover {
    background: #e11b22;
    color: #ffffff;

    svg path {
      fill: #ffffff;
    }
  }

  li.disabled,
  button:disabled {
    pointer-events: none;
    color: #000000;
    background: rgb(244, 243, 244);
    opacity: 0.5;
  }

  .firstBtn {
    svg {
      transform: scale(1.5);
    }
  }

  .prevBtn {
    svg {
      transform: scale(1.2);
    }
  }
}
