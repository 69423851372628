.respass-container {
  .wrapper {
    border: 1px solid #d8dde6;
    background: #ffffff;
    width: 500px;

    img {
      width: 250px;
    }
  }

  .title {
    color: #000000;
  }

  .subtitle {
    color: #16325c;
  }
}
.forgotPass-link {
  color: #0c91ec;
}

.forgotPass-link:hover {
  text-decoration: underline;
}
