.product-details-container .accordianWrapper {
  .accordion {
    border: 0;
  }
  .accIconOpen,
  .accIconClose {
    content: "";
    height: 10px;
    width: 10px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
  }
  .accIconOpen {
    transform: rotate(-135deg);
  }

  .accButton {
    background: #f4f3f4;
  }

  .accordion__panel {
    padding: 0px;
    animation: none;
  }

  .accPanel {
    background: #ffffff;

    .fieldWrapper {
      border-bottom: 1px solid #dadada;
    }
    .fieldName {
      color: #717171;
    }

    .fieldDetails {
      color: #000000;

      .addressData {
        border: 1px solid #dadada;
        min-height: 110px;
      }
    }

    .userData {
      color: #e11b22;

      img {
        border: 3px solid #f3f4f5;
        height: 25px;
      }
    }
  }

  .accTitle {
    color: #424242;
  }

  .slickWrapper {
    max-width: 370px;
    margin: 0 auto;

    .slick-track {
      display: flex;
      align-items: center;
    }
  }
  .slickWrapper2 {
    margin: 0 auto;
    max-width: 700px;
    position: relative;
    padding: 0px 20px;
    height: 500px;
    width: 100%;
    cursor: pointer;
  }
  .slickWrapper .viewBtn,
  .slickWrapper2 .viewBtn {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    button {
      background: #e11b22;
      color: #ffffff;
      border-radius: 0.5rem;
      padding: 5px 30px;
    }
  }
  .slickWrapper .img:hover .viewBtn,
  .slickWrapper2:hover .viewBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
  }
  .slideNum {
    height: 32px;
    border: 1px solid #e11b22;
  }

  .nextButton,
  .prevButton {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #f1f1f1;

    svg {
      transform: scale(1.5);
    }
  }

  .no-img {
    img {
      height: 200px;
    }
  }
}
