.card-header {
  background: #fff4f4;

  .title-img {
    background: #e11b22;
  }

  .text {
    color: #3c3c3c;
  }

  .title {
    color: #000000;
  }

  .titlesub {
    background: #ffffff;
    border: 1px solid #dadada;
  }

  .search-select {
    min-width: 250px;
  }
}
