.order-details-container .accordianWrapper {
  .accordion {
    border: 0;
  }
  .accIconOpen,
  .accIconClose {
    content: "";
    height: 10px;
    width: 10px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
  }
  .accIconOpen {
    transform: rotate(-135deg);
  }

  .accButton {
    background: #f4f3f4;
  }

  .accordion__panel {
    padding: 0px;
    animation: none;
  }

  .accPanel {
    background: #ffffff;

    .fieldWrapper {
      border-bottom: 1px solid #dadada;
    }
    .fieldName {
      color: #717171;
    }

    .fieldDetails {
      color: #000000;

      .addressData {
        border: 1px solid #dadada;
        min-height: 110px;
      }
    }

    .userData {
      color: #e11b22;

      img {
        border: 3px solid #f3f4f5;
        height: 25px;
      }
    }
  }

  .accTitle {
    color: #424242;
  }
}
.w-70 {
  width: 70vw;
}
.order-list-table {
  border: 1px solid #f4f3f4;
}
.order-list-table td {
  border-bottom: 1px solid #f4f3f4;
  font-weight: 400;
}
