.btn {
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-color: transparent;
  padding: 8px 15px;
  width: fit-content;
  text-transform: uppercase;
  min-width: 110px;
}

.btn-primary,
.btn-primary:hover {
  background: #e11b22 !important;
  color: #ffffff !important;
}

.btn-secondary,
.btn-secondary:hover {
  background: #888888 !important;
  color: #ffffff !important;
}

.btn-primary-outlined,
.btn-primary-outlined:hover {
  background-color: transparent;
  border: 1px solid #e11b22 !important;
  color: #e11b22 !important;
}

.btn-secondary-outlined,
.btn-secondary-outlined:hover {
  background-color: transparent;
  border: 1px solid #424242 !important;
  color: #424242 !important;
}
