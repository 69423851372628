#sidebar {
  width: 110px;
  height: calc(100vh - 70px);
  background: #ffffff;
  margin-top: 70px;

  .menuItem {
    color: #3c3c3c;

    svg {
      margin: 5px 0px 12px;
      height: 24px;
    }
  }

  .menuItem:hover {
    background-color: #f4f3f4;
  }

  .menuItem.active {
    background: #e11b22;
    box-shadow: 0px 2px 2px rgba(225, 27, 34, 0.25);
    color: #ffffff;
  }
}
