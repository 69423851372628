// .fontSize {
//   font-size: 16px;
//   font-weight: 600;
// }

// .titleItem {
//   padding-left: 16px;
//   padding-right: 16px;
//   padding-bottom: 20px;
//   font-weight: 600;
// }

// .bodyItem {
//   max-height: 65vh;
//   overflow-y: auto;
// }

// .footerItem {
//   padding-top: 20px;
// }

// .floatRight {
//   float: right;
// }

// .secImage {
//   cursor: pointer;
//   width: 100%;
//   border-radius: 5px;
// }

// .editModal svg {
//   font-size: 24px;
//   cursor: pointer;
// }

// .td {
//   padding: 10px;
// }

// .tdInput {
//   width: 60%;
//   padding: 10px;
// }

// .xIcon {
//   color: #ff0000;
// }

// .xIconTemp {
//   opacity: 0;
//   cursor: default;
//   pointer-events: none;
// }

// .popoverContent {
//   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//   padding: 10px;
//   background: #ffffff;
//   border-radius: 5px;
// }

// .HeaderPopoverImg {
//   margin-right: 10px;
//   object-fit: cover;
//   object-position: 5%;
// }

// .modalTable {
//   width: 100%;
// }

// .formSelect > div {
//   border: 0;
// }

// .removeImage {
//   display: flex;
//   position: absolute;
//   top: -15px;
//   right: 0px;
//   cursor: pointer;
//   border-radius: 50%;
//   background: white;
//   padding: 5px 9px;
//   box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
// }

// .modalClass {
//   max-width: 80vw !important;
//   width: 100% !important;
// }

// .modalBody {
//   padding: 0;
//   margin: 0rem 1rem;
//   padding: 1rem 0rem;
//   border-top: 1px solid #dee2e6;
//   border-bottom: 1px solid #dee2e6;
// }

// .colorWrapClass {
//   max-width: 60px;
//   min-width: 60px;
// }

// .fontWrapClass {
//   max-width: 200px;
//   min-width: 200px;
// }

// .linkWrapFooter1 {
//   max-width: 800px;
//   min-width: 800px;
// }

// .linkWrapClass {
//   max-width: 400px;
//   min-width: 400px;
// }

// ======= dialog =======
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
// ==================

.modal-header {
  color: #000000;
  border-bottom: 1px solid #dadada;
  background-color: #ffffff;
  z-index: 1;
}

// .modal-body {
//   margin-top: 73px;
//   margin-bottom: 80px;
//   max-height: calc(100% - 3.5rem);
// }

.modal-footer {
  border-top: 1px solid #dadada;
  background-color: #ffffff;
  z-index: 1;
}

.step1-container {
  .title {
    color: #424242;
  }

  .details {
    color: #717171;
  }
}

.step2-container {
  width: 70vw;
  .title {
    background: #f4f3f4;
    color: #424242;
  }

  .fieldName {
    color: #717171;
  }
}

.productList-container {
  min-width: 70vw;

  .notice {
    color: #00a328;
  }
}

.product-list-dialog .table-spinner {
  bottom: 0 !important;
}

.dnd-input {
  height: 200px;
  border-color: rgb(102, 102, 102);
  background-color: rgb(240, 240, 240);
  color: rgb(189, 189, 189);
}

.change-pass-container {
  // width: 20vw;
  width: 400px;
}
