.accounts-container .accordianWrapper {
  .accordion {
    border: 0;
  }
  .accIconOpen,
  .accIconClose {
    content: "";
    height: 10px;
    width: 10px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
  }
  .accIconOpen {
    transform: rotate(-135deg);
  }

  .accButton {
    background: #f4f3f4;
  }

  .accordion__panel {
    padding: 0px;
    animation: none;
  }

  .accPanel {
    background: #ffffff;

    .fieldWrapper {
      border-bottom: 1px solid #dadada;
    }
    .fieldName {
      color: #717171;
    }

    .fieldDetails {
      color: #000000;

      .userData {
        color: #e11b22;

        img {
          border: 3px solid #f3f4f5;
          height: 25px;
        }

        div {
          background-color: #e11b22;
          color: #ffffff;
          height: 25px;
          width: 25px;
        }
      }

      .addressData {
        border: 1px solid #dadada;
        min-height: 110px;
      }
    }
  }

  .accTitle {
    color: #424242;
  }
}
