.order-create-container {
  background: #ffffff;

  .title {
    background: #f4f3f4;
    color: #424242;
  }

  .gtotal {
    color: #717171;
  }

  .gtotalval {
    color: #000000;
  }

  table {
    thead {
      background: #e7e8ef;
      border-radius: 8px 8px 0px 0px;

      th {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #717171;
        text-align: start;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #dadada;
        border-left: 1px solid #dadada;
        border-right: 1px solid #dadada;

        td {
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #000000;
        }
      }
    }

    .no-table-record {
      color: #000000;
    }
  }

  .pagination {
    button {
      color: #000000;
      padding: 10px;
      background: #ffffff;
      border-radius: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      width: 32px;
      height: 32px;
    }

    button.active,
    button:hover {
      background: #e11b22;
      color: #ffffff;

      svg path {
        fill: #ffffff;
      }
    }

    button:disabled {
      pointer-events: none;
      color: #000000;
      background: rgb(244, 243, 244);
      opacity: 0.5;
    }

    .firstBtn {
      svg {
        transform: scale(1.5);
      }
    }

    .prevBtn {
      svg {
        transform: scale(1.2);
      }
    }
  }
}

.fieldDetails {
  color: #000000;

  .userData {
    color: #e11b22;

    img {
      border: 3px solid #f3f4f5;
      height: 25px;
    }

    div {
      background-color: #e11b22;
      color: #ffffff;
      height: 25px;
      width: 25px;
    }
  }

  .addressData {
    border: 1px solid #dadada;
    min-height: 110px;
  }
}
